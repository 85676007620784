; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.mastertag = {
        name: 'mastertag',

        version: '1.0.0',

        settings: {
            trigger: '',    // 'click' ou 'autopromo'
            event: '',     // 'C' ou 'autopromo_click'
            type: '',     // 'N'=Navigation, 'S'=Sortie, 'A'=Action, 'T'=Téléchargement
            level: '',      // N° de site de Niveau 2 AT Internet
            label: '',      // Chapitrage AT Internet avec notation chapitre::sous_chapitre::clic
            name: '',       // Libellé de l'event
            product_size: '',       // Taille du produit
            product_add_type: '',   // 'classique', 'catalogue', 'mixte' => Type d'ajout au panier
            completeCallback: function (request, status) { }
        },

        init: function (scope, method, options) {
            this.settings = this.settings || $.extend({}, this.defaults, (options || method));

            // à conserver
            this.bindings(method, options);
        },

        // Méthode globale 
        track_event: function (obj, trigger, options) {
            if (typeof(tC) !== "undefined" && tC && tC.event) {
                switch (trigger) {
                    case "clic_menu":
                        if (typeof tC.event.clic_menu === "function") {
                            tC.event.clic_menu(this, options);
                        }
                        break;
                    case "clic_carrousel_produits":
                    case "clic_liste_produit":
                    case "clic_wishlist_produit":
                    case "clic_configurateur_produit":
                        if (typeof tC.event.product_click === "function") {
                            tC.event.product_click(this, options);
                        }
                        break;
                    case "ajout_panier":
                        if (typeof tC.event.ajout_panier === "function") {
                            tC.event.ajout_panier(this, options);
                        }
                        break;
                    case "clic_favoris":
                        if (typeof tC.event.clic_favoris === "function") {
                            tC.event.clic_favoris(this, options);
                        }
                        break;
                    case "e_reservation":
                        if (typeof tC.event.e_reservation === "function") {
                            tC.event.e_reservation(this, options);
                        }
                        break;
                    case "onglet_fp":
                        if (typeof tC.event.onglet_fp === "function") {
                            tC.event.onglet_fp(this, options);
                        }
                        break;
                    case "emballage_cadeau":
                        if (typeof tC.event.emballage_cadeau === "function") {
                            tC.event.emballage_cadeau(this, options);
                        }
                        break;
                    case "retrait_produit":
                        if (typeof tC.event.retrait_produit === "function") {
                            tC.event.retrait_produit(this, options);
                        }
                        break;
                    case "validation_panier":
                    case "choix_livraison":
                    case "choix_paiement":
                    case "modification_paiement":
                        if (typeof tC.event.checkout_progress === "function") {
                            tC.event.checkout_progress(this, options);
                        }
                        break;
                    case "login":
                    case "clic_type_identification":
                        if (typeof tC.event.login === "function") {
                            tC.event.login(this, options);
                        }
                        break;
                    case "subscription":
                        if (typeof tC.event.subscription === "function") {
                            tC.event.subscription(this, options);
                        }
                        break;
                    case "clic_configurateur_critere":
                        if (typeof tC.event.clic_configurateur_critere === "function") {
                            tC.event.product_interaction(this, options);
                        }
                        break;
                    case "guide_des_tailles":
                        if (typeof tC.event.guide_des_tailles === "function") {
                            tC.event.guide_des_tailles(this, options);
                        }
                        break;
                    default: break;
                }
            }

            if (typeof (tc_vars) != "undefined") {
                if (tc_vars['env_work'] != 'PRODUCTION') {
                    //console.log("track_event : '" + trigger + "' // options = " + JSON.stringify(options));
                    console.log(trigger);
                    console.dir(options);
                }
            }
        },
        track_tc_event: function (data) {
            this.track_event(data.obj, data.trigger, data.options);
        },
        trackAddToCart: function (infos) {
            var options = {
                id: "ajout_panier",
                category: infos.category,
                action: tc_vars.order_last_product_added.product_category,
                label: tc_vars.order_last_product_added.product_name + '|' + tc_vars.order_last_product_added.product_id,
                value: tc_vars.order_last_product_added.product_unitprice_ati,
                product_cat1: tc_vars.order_last_product_added.product_cat1,
                product_cat2: tc_vars.order_last_product_added.product_cat2,
                product_cat3: tc_vars.order_last_product_added.product_cat3,
                product_category: tc_vars.order_last_product_added.product_category,
                product_discount_tf: tc_vars.order_last_product_added.product_discount_tf,
                product_discount_ati: tc_vars.order_last_product_added.product_discount_ati,
                product_gravable: tc_vars.order_last_product_added.product_gravable,
                product_engraved: tc_vars.order_last_product_added.product_engraved,
                product_id: tc_vars.order_last_product_added.product_id,
                product_name: tc_vars.order_last_product_added.product_name,
                product_promo: tc_vars.order_last_product_added.product_promo,
                product_promo_label: tc_vars.order_last_product_added.product_promo_label,
                product_quantity: tc_vars.order_last_product_added.product_quantity,
                product_rating: tc_vars.order_last_product_added.product_rating,
                product_size: tc_vars.order_last_product_added.product_size,
                product_trademark: tc_vars.order_last_product_added.product_trademark,
                product_unitprice_ati: tc_vars.order_last_product_added.product_unitprice_ati,
                product_unitprice_tf: tc_vars.order_last_product_added.product_unitprice_tf
            };

            this.track_event(this, infos.trigger, options);
        },
        events: function () {
            var self = this;

            $(this.scope)
                .off('.fndtn.mastertag')
                .on('click.fndtn.mastertag', '[' + self.attr_name() + ']', function (evt) {

                    self.settings = $.extend({}, self.defaults, self.data_options($(this)));

                    var trigger = $(this).data('trigger');

                    switch (trigger) {
                        case "autopromo":
                            if (typeof (tc_vars) != "undefined") {
                                var evt_opt = {
                                };

                                var action_type = self.settings['action_type'];
                                if (typeof (action_type) != "undefined") {
                                    switch (action_type) {
                                        case 'homeProduct':
                                        case 'carrouselProduct':
                                            var category = self.settings['carrousel_type'];

                                            if (typeof(category) === "undefined" || category == "") {
                                                var zone = $(this).attr('data-zone');
                                                switch (zone) {
                                                    case "FP_VOUS_AIMEREZ_AUSSI":
                                                        category = "vous aimerez aussi";
                                                        break;
                                                    case "FP_MUST_HAVE":
                                                        category = "must have";
                                                        break;
                                                    case "FP_DERNIERS_ARTICLES_VUS":
                                                        category = "derniers articles vus";
                                                        break;
                                                }
                                            }

                                            var url = $(this).data("href");
                                            if (url) {
                                                url = decodeURIComponent(window.atob(url)).toLowerCase();
                                            }
                                            else {
                                                url = $(this).attr("href").toLowerCase();
                                            }

                                            self.track_event(this, 'clic_carrousel_produits', {
                                                id: "clic_produit",
                                                category: category,
                                                action: self.settings['product_name'].replace(new RegExp("'", 'g'), '') + '|' + self.settings['product_id'].replace(new RegExp("'", 'g'), ''),
                                                label: url,
                                                value: self.settings['product_price'],
                                                product_id: self.settings['product_id'].replace(new RegExp("'", 'g'), ''),
                                                product_name: self.settings['product_name'].replace(new RegExp("'", 'g'), ''),
                                                product_trademark: self.settings['product_brand'],
                                                product_promo: self.settings['product_promo'],
                                                product_promo_label: self.settings['product_promo_label'],
                                                product_unitprice_ati: self.settings['product_unitprice_ati'],
                                                product_unitprice_tf: self.settings['product_unitprice_tf'],
                                                product_discount_ati: self.settings['product_discount_ati'],
                                                product_discount_tf: self.settings['product_discount_tf'],
                                     
                                                product_category: self.settings['product_category']
                                                
                                     
                                            });
                                            break;
                                    }
                                }
                            }

                            break;
                        case "produit-onglet":
                            self.track_event(this, 'onglet_fp', evt_opt = {
                                id: 'onglet_fp',
                                category: 'onglet fiche produit',
                                action: self.settings['target_name']
                            });
                            break;
                    }
                });
        },
        reflow: function () { }
    };
}(jQuery, window, window.document));