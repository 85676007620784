Foundation.utils.register_media('smallOnly', 'foundation-mq-small-only');
Foundation.utils.register_media('mediumOnly', 'foundation-mq-medium-only');
Foundation.utils.register_media('largeOnly', 'foundation-mq-large-only');

var topbarMegamenuTimer = null;

function initInputPasswordToggle() {
    $(".a-input__toggle").on('click touchend', function (e) {
        e.preventDefault();
        var $this = $(e.target);
        var input = $this.siblings(".a-input")[0];

        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }

    })
}

function LoadImage(image, classname, cb) {
    var imageLoader = new Image()
    var src = image.dataset.src;
    var backgroundSrc = image.dataset.bgSrc;

    for (var i = 0; i < image.parentNode.children.length; i++) {
        var source = image.parentNode.children[i]
        if (source.dataset.srcset) {
            source.srcset = source.dataset.srcset
        }
    }
    imageLoader.onload = function () {
        
        if (src) {
            image.src = src
        } else if (backgroundSrc) {
            image.style.backgroundImage = "url('" + backgroundSrc + "')"
        } 

        image.classList.remove(classname)
        if (cb) cb()
    }
    if (src) {
        imageLoader.src = src
    } else if (backgroundSrc) {
        imageLoader.src = backgroundSrc
    }
    
}


// Polyfill pour les events passif
var supportsPassive = false;
try {
    var opts = Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    });
    window.addEventListener("testPassive", null, opts);
    window.removeEventListener("testPassive", null, opts);
} catch (e) { }

$(document).ready(function () {
    // "/lib/checkVisibility/maty.checkVisibilit.js" Check les éléments à afficher ou cacher selon la date.
    new CheckVisibility();

    $(".has-mq-class").mqClass();
    $(document).foundation();
    //$(document).foundation('topbar', 'init');
    $(window).on('resize', Foundation.utils.throttle(function () {
        $(".has-mq-class").mqClass();
        //$(document).foundation('topbar', 'init');
        $(document).foundation();
    }, 150, true));


    $(".small-menu-icon").on("click", Foundation.utils.throttle(function () {

        $(".toggle-topbar").trigger("click");

        if ($(".top-bar").hasClass("expanded")) {
            $(".title-area").hide();
            $(".top-bar-section").show();
            $(".top-bar").show();
        } else {
            $(".top-bar").hide();
            $(".top-bar-section").hide();
        }

    }, 300, true));

    if (matchMedia(Foundation.media_queries.largeOnly).matches) {
        $(document).foundation({
            'magellan-expedition': {
                fixed_top: '2rem',
                threshold: -32
            }
        });
    }

    $('.has-dropdown.not-click').off("mousenter").on('mouseenter', function () {
        $(".top-bar").foundation('contentoverlay', 'show');
    });

    $('.has-dropdown.not-click').off('mouseleave').on('mouseleave',
        function () {
            $(".top-bar").delay(75).foundation('contentoverlay', 'hide');
        }
    );

    $('.top-bar .dropdown-megamenu-wrapper').off("mousenter").on('mouseenter',
        function () { $(".top-bar").foundation('contentoverlay', 'show'); }
    );

    $('.m-selectDropdown__input').on("change", function (event) {
        event.preventDefault();
        var value = this.value;
        var className = "-active"
        var $parent = $(event.target).parents('.m-selectDropdown');
        $parent.find(".m-selectDropdown__content").removeClass(className);
        $parent.find(value).addClass(className);

        //$parent.toggleClass("-active");
    });
    $('.m-dropdown__link').on("click", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        var $parent = $(event.target).parents('.m-dropdown');
        $parent.toggleClass("-active");
    });
    $('.t-subPage__back').on("click", function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        var $parent = $(event.target).parents('.t-subPage');
        document.body.style.overflow = 'visible';
        $parent.removeClass("-active");
    })

    $('#a-conditions-offres').click(function (event) {
        event.preventDefault();
        window.location.href = "/offre-maty/code-promo-conditions.html";
    })

});

$(function () {

    if (!matchMedia(Foundation.media_queries.smallOnly).matches) {
        var megaMenuContainer = Foundation.utils.S(".dropdown-megamenu-wrapper", Foundation.utils.S(".top-bar-section"));

        megaMenuContainer.each(function () {
            var currentMegaMenu = $(this);
            var columns = {};

            currentMegaMenu.children('li').wrapAll('<li><div class="row"></div></li>');

            Foundation.utils.S("[data-column]", currentMegaMenu).each(function () {
                var $this = $(this);
                var columnName = $this.data("column");

                if (columns.hasOwnProperty(columnName) === false) {
                    columns[columnName] = $this.data("column-class");
                }
            });

            $.each(columns, function (key, val) {
                Foundation.utils.S("[data-column='" + key + "']", currentMegaMenu).wrapAll("<div class='" + val + "'><ul></ul></div>");
            });
        });
    }

});