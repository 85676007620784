; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.hiturl = {
        name: 'hiturl', 

        version: '1.0.0',

        settings: {
            completeCallback: function (request, status) {  }
        },

        init: function (scope, method, options) {
            // initialisation à conserver
            //Foundation.inherit(this, '');
            //var self = this;

            // à conserver
            this.bindings(method, options);
        },

        events: function () {
            var self = this;
            var S = this.S;
            
            $(this.scope)
            .off('.fndtn.hiturl')
            .on('click.fndtn.hiturl', '[' + self.attr_name() + ']', function (event) {
                
                var declencheur = S(this).closest('[' + self.attr_name() + ']');
                //console.log('urlToHit : Debut');
                event.preventDefault();
                
                var urlToHit = declencheur.attr("data-hiturl");
                
                $.ajax({
                    url: urlToHit,
                    timeout: 5,
                    complete: function (request, status) {
                        self.settings.completeCallback(request, status);
                        
                        var urlHref = declencheur.attr("href");
                        if (urlHref.length !== 0)
                            window.location.href = declencheur.attr("href");

                        declencheur.triggerHandler('hiturl');
                    }
                });
                
            });
        },

        reflow: function () { }
    };
}(jQuery, window, window.document));

