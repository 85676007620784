var LazyLoad = /** @class */ (function () {
    function LazyLoad(className) {
        var _this = this;
        this.reset = this.reset.bind(this);
        window.addEventListener("DOMContentLoaded", function () {
            _this.className = className;
            _this.selector = "." + className;
            _this.elements = document.querySelectorAll(_this.selector);
            _this.init();
        });
    }
    LazyLoad.prototype.init = function () {
        if ("IntersectionObserver" in window) {
            this.initObserver();
        }
        else {
            this.initPolyfill();
        }
    };

    LazyLoad.prototype.reset = function () {
       
        this.elements = document.querySelectorAll(this.selector);
        if ("IntersectionObserver" in window) {
            this.observer.disconnect();
            
            this.initObserver();
        } 
    };
    LazyLoad.prototype.initObserver = function () {
        var _this = this;
        this.observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var image_1 = entry.target;
                    LoadImage(image_1, _this.className, function () {
                        if (_this.observer) {
                            _this.observer.unobserve(image_1);
                        }
                        _this.elements = document.querySelectorAll(_this.selector);
                    });
                }
            });
        });
       
        for (var i = 0; i < this.elements.length; i++) {
            var element = this.elements[i];
            this.observer.observe(element);
        }
    };
    LazyLoad.prototype.elementInViewport = function (el) {
        var rect = el.getBoundingClientRect();
        return (rect.top >= 0
            && rect.left >= 0
            && rect.top <= (window.innerHeight || document.documentElement.clientHeight));
    };
    LazyLoad.prototype.customObserver = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var element = this.elements[i];
            if (this.elementInViewport(element)) {
                LoadImage(element);
            }
        }
    };
    LazyLoad.prototype.initPolyfill = function () {
        var _this = this;
        this.customObserver();
        document.addEventListener("scroll", function () { return _this.customObserver(); });
    };
    return LazyLoad;
}());

window.LazyLoad = new LazyLoad("js-lazyLoad");
