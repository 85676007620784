; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.scroll = {
        name: 'scroll',

        version: '1.0.0',

        settings: {
            active_class: "fixed",
            custom_offset: -40,  // Décalage pour prendre en compte la hauteur du header sticky qui cacherait l'élément sur lequel on demande à scroller
            callback: function () { }
        },

        init: function (scope, method, options) {
            // initialisation à conserver
            //Foundation.inherit(this, '');
            //var self = this;
            var _this = this;
            var scrollBtn = $('.scrollToTop');
            if (window.matchMedia(Foundation.media_queries.large).matches) {
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 150) {
                        scrollBtn.fadeIn();
                    } else {
                        scrollBtn.fadeOut();
                    }
                });

                scrollBtn.click(function (event) {
                    event.preventDefault();
                    $('body,html').animate({ scrollTop: 0 }, 800);
                    return false;
                });
            }
            
            if (window.matchMedia(Foundation.media_queries.smallOnly).matches) {
               
                var $header = $('#main-header');
                var headerHeight = $header.outerHeight();
                $(window).scroll(function () {
                    if ($(this).scrollTop() > headerHeight) {
                        $("body").addClass("padding");
                        $header.addClass(_this.settings.active_class);
                    } else {
                        $("body").removeClass("padding");
                        $header.removeClass(_this.settings.active_class);
                    }
                });

            }

            // à conserver
            this.bindings(method, options);
        },

        events: function () {
            var self = this;
            var S = this.S;

        },

        moveTo: function (target) {
            var topY = 0;

            if (target.length > 0) {
                var coord = target.offset();
                topY = coord.top + this.settings.custom_offset;
            }

            $('body,html').animate({ scrollTop: topY }, 800);
        },

        moveToTop: function () {
            $('body,html').animate({ scrollTop: 0 }, 800);
        },

        moveUntilVisible: function(element) {
            var offset = element.offset().top - $(window).scrollTop(); 
            if (offset > window.innerHeight || offset < window.innerHeight) { 
                $('body,html').animate({ scrollTop: offset }, 800);
            }
        },

        reflow: function () { }
    };
}(jQuery, window, window.document));

