(function ($) {
    $.fn.mqClass = function () {

        var elem = this;

        var init = function (elem) {

            elem.each(function () {

                var dataSmall = $(this).attr("data-mq-class-small");
                var dataMedium = $(this).attr("data-mq-class-medium");
                var dataLarge = $(this).attr("data-mq-class-large");

                if (matchMedia(Foundation.media_queries.smallOnly).matches) {
                    if (dataSmall !== undefined && dataSmall.length > 0) {
                        // Remove specific classes that don't match current breakpoint
                        $(this).removeClass(dataMedium);
                        $(this).removeClass(dataLarge);

                        // Add classes asked for the current breakpoint
                        if (!$(this).hasClass(dataSmall))
                            $(this).addClass(dataSmall);
                    }
                }

                if (matchMedia(Foundation.media_queries.mediumOnly).matches) {
                    if (dataMedium !== undefined && dataMedium.length > 0) {
                        // Remove specific classes that don't match current breakpoint
                        $(this).removeClass(dataSmall);
                        $(this).removeClass(dataLarge);

                        // Add classes asked for the current breakpoint
                        if (!$(this).hasClass(dataMedium))
                            $(this).addClass(dataMedium);
                    }
                }

                if ((matchMedia(Foundation.media_queries.large).matches) || (matchMedia(Foundation.media_queries.largeOnly).matches) || (matchMedia(Foundation.media_queries.xlarge).matches) || (matchMedia(Foundation.media_queries.xxlarge).matches)) {
                    // Remove specific classes that don't match current breakpoint
                    $(this).removeClass(dataSmall);
                    $(this).removeClass(dataMedium);

                    // Add classes asked for the current breakpoint
                    if (dataLarge !== undefined && dataLarge.length > 0) {
                        if (!$(this).hasClass(dataLarge))
                            $(this).addClass(dataLarge);
                    }
                }

            });

            return elem;
        };

        init(elem);
    };

}(jQuery));
// Usage example:
// <div class="has-mq-class" data-mq-class-medium="has-dropdown">...</div>
// $(".has-mq-class").showLinkLocation();
//
// Result:
// the div is added the class "has-dropdown" only if the page matches the "medium" breakpoints
// <div class="has-mq-class has-dropdown" data-mq-class-medium="has-dropdown">...</div>


