; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.toggle = {
        name: 'toggle', 

        version: '1.0.0',

        settings: {
            active_class: "toggled",
            callback: function () { }
        },

        init: function (scope, method, options) {
            // initialisation à conserver
            //Foundation.inherit(this, '');
            //var self = this;

            // à conserver
            this.bindings(method, options);
        },

        events: function () {
            var self = this;
            var S = this.S;
            
            $(this.scope)
            .off('.fndtn.toggle')
            .on('click.fndtn.toggle', '[' + self.attr_name() + ']', function (event) {
                var declencheur = S(this).closest('[' + self.attr_name() + ']');
                var target = S('.' + declencheur.attr(self.attr_name()));


                if (declencheur.data("toggle-type") == "radio"
                    || declencheur.data("toggle-type") == "checkbox") {
                    
                    // Dans le cas d'un comportement de type "RADIO", si on coche un bouton, il faut "désactiver tous les autres...
                    if (declencheur.data("toggle-type") == "radio") {
                        var name = declencheur.attr("name");
                        var others = S('input[name = "' + name + '"]', self.scope).not(declencheur);
                        var otherTarget;
                        others.each(function () {
                            //$(this).removeAttr("checked");
                            $("." + $(this).attr("data-toggle")).removeClass(self.settings.active_class);
                        });
                    }

                    // Attention, à cet endroit, on a l'état de la checkbox ou du radio AVANT click (l'élément changera d'état à la fin de cette méthode)
                    if (declencheur.attr("checked") == "checked" && declencheur.data("toggle-type") == "checkbox")
                    {
                        // Toggle des éléments cibles
                        target.removeClass(self.settings.active_class);
                        // Toggle du déclencheur
                        declencheur.removeClass(self.settings.active_class);
                    }
                    else {
                        // Toggle des éléments cibles
                        target.removeClass(self.settings.active_class);
                        target.addClass(self.settings.active_class);
                        // Toggle du déclencheur
                        declencheur.removeClass(self.settings.active_class);
                        declencheur.addClass(self.settings.active_class);
                    }
                }
                else {
                    event.preventDefault();
                    // Toggle des éléments cibles
                    target.toggleClass(self.settings.active_class);
                    // Toggle du déclencheur
                    declencheur.toggleClass(self.settings.active_class);
                }

                

                // Mise à jour de l'intitulé du déclencheur
                if (declencheur.hasClass(self.settings.active_class))
                {
                    if (typeof(declencheur.attr("data-on-value")) != "undefined") {
                        declencheur[0].innerHTML = declencheur.attr("data-on-value");
                    }
                }
                else
                {
                    if (typeof(declencheur.attr("data-off-value")) != "undefined") {
                        declencheur[0].innerHTML = declencheur.attr("data-off-value");
                    }
                }

                // On soulève un event pour pouvoir étendre le comportement par défaut
                target.triggerHandler('toggled', [declencheur]);
                declencheur.triggerHandler('toggled', [target]);

            })
            ;
        },

        reflow: function () { }
    };
}(jQuery, window, window.document));

