var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var CheckVisibility = /** @class */ (function () {
    function CheckVisibility(options) {
        this.dateNow = null;
        this.DOM = {
            elements: null
        };
        this.options = {
            classname: "checkvisibility"
        };
        this.DOM.elements = document.querySelectorAll("." + this.options.classname);
        if (this.DOM.elements && document.body.dataset.dateNow) {
            if (options) {
                this.options = __assign(__assign({}, this.options), options);
            }
            this.dateNow = this.formatDate(document.body.dataset.dateNow);
            this.checkElements();
        }
    }
    CheckVisibility.prototype.formatDate = function (textDate) {
        var date = textDate.split(" ")[0].split("/");
        var time = textDate.split(" ")[1].split(":");
        return new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]), parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
    };
    CheckVisibility.prototype.checkElements = function () {
        for (var i = 0; i < this.DOM.elements.length; i++) {
            var element = this.DOM.elements[i];
            var dateStart = element.dataset.showAt ? this.formatDate(element.dataset.showAt) : null;
            var dateEnd = element.dataset.hideAt ? this.formatDate(element.dataset.hideAt) : null;
            if (dateStart && dateEnd) {
                if (this.dateNow <= dateEnd && this.dateNow >= dateStart) {
                    element.classList.remove(this.options.classname);
                }
                else {
                    element.classList.add(this.options.classname);
                }
            }
            else if (dateStart) {
                if (this.dateNow >= dateStart) {
                    element.classList.remove(this.options.classname);
                }
            }
            else if (dateEnd) {
                if (this.dateNow <= dateEnd) {
                    element.classList.remove(this.options.classname);
                }
                else {
                    element.classList.add(this.options.classname);
                }
            }
        }
    };
    return CheckVisibility;
}());
