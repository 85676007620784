var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 *
 * Gère tous les fileinputs
 * On donne un sélecteur en paramètre ex : FileInput(".selecteur");
 *
 *
 * Sélectionne l'input associé au sélecteur
 * Va chercher "data-valmsg-for" dans l'html associé au nom de l'input pour les messages d'erreurs
 * Et "data-preview-for" dans l'html associé au nom de l'input pour afficher une preview
 * Dans les 2 cas il ne se passe rien s'ils sont inexistants
 *
 * Ajout de 6 fonctions en options "onInit", "onChange"et "onRemove" pour faire d'autres choses à l'initialisation ou au changement ou suppression de fichier
 *
 * */
var FileInput = /** @class */ (function () {
    function FileInput(selector, options) {
        this.formats = [];
        this.initClassName = "-isInit";
        this.options = {
            minKo: 5,
            maxKo: 4000,
        };
        if (!$(selector).hasClass(this.initClassName)) {
            this.init(selector, options);
        }
    }
    FileInput.prototype.init = function (selector, options) {
        if (options) {
            this.options = __assign(__assign({}, this.options), options);
        }
        this.selector = selector;
        // cherche les éléments du DOM
        this.DOM = {
            input: $(selector),
            errorWrapper: $("[data-valmsg-for='".concat($(selector).attr("id"), "']")),
            imageWrapper: $("[data-preview-for='".concat($(selector).attr("id"), "']")),
        };
        this.DOM.input.addClass(this.initClassName);
        if ($(this.selector).attr("accept") != null) {
            this.formats = $(this.selector).attr("accept").split(", ").map(function (format) { return format.replace(".", ""); });
        }
        this.addEvents();
        if (this.options.onInit) {
            this.options.onInit.bind(this)();
        }
    };
    FileInput.prototype.changeFileNameText = function (input, filename) {
        var _this = this;
        var $container = $(input).parent();
        if ($container.find(".a-filename")) {
            $container.find(".a-filename").remove();
        }
        if (filename) {
            $container.append('<p class="a-filename"><b>Image :</b> ' + filename + ' <span class="a-filename__remove" title="Supprimez votre dessin"></span></p>');
            $(".a-filename__remove").bind("click", function (e) {
                e.preventDefault();
                _this.removeFile();
            });
        }
    };
    FileInput.prototype.removeFile = function () {
        if ($(".a-filename").get(0)) {
            $(".a-filename__remove").unbind("click");
            $(".a-filename").remove();
        }
        this.DOM.input.val("");
        this.removeImagePreview();
        if (this.options.onRemove) {
            this.options.onRemove.bind(this)();
        }
    };
    FileInput.prototype.addImagePreview = function (src, fileExtension) {
        var div = document.createElement("div");
        if (fileExtension === "pdf") {
            var iframe = document.createElement("iframe");
            iframe.src = src;
            div.appendChild(iframe);
            this.DOM.imageWrapper.html(div);
        }
        else {
            var image = new Image();
            image.src = src;
            div.appendChild(image);
            this.DOM.imageWrapper.html(div);
        }
    };
    FileInput.prototype.removeImagePreview = function () {
        this.DOM.imageWrapper.html("");
    };
    FileInput.prototype.readURL = function (input, filename, fileExtension) {
        var _this = this;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var src = e.target.result;
                _this.file = {
                    src: src,
                    name: filename,
                    ext: fileExtension
                };
                if (_this.DOM.imageWrapper.get(0)) {
                    _this.addImagePreview(src, fileExtension);
                }
                _this.changeFileNameText(input, filename);
                if (_this.options.onChange) {
                    _this.options.onChange.bind(_this)();
                }
            };
            reader.readAsDataURL(input.files[0]);
        }
    };
    FileInput.prototype.checkFileExtension = function (ext) {
        var isValid = false;
        if (this.formats.length === 0) {
            isValid = true;
        }
        else {
            this.formats.forEach(function (format) {
                if (format === ext) {
                    isValid = true;
                }
            });
        }
        return isValid;
    };
    FileInput.prototype.addEvents = function () {
        var _this = this;
        this.DOM.input.change(function (e) {
            var input = e.target;
            if (input.value) {
                var file = input.files[0];
                var fileName = file.name;
                var fileSize = Math.round((file.size / 1024) * 100) / 100;
                var dotIndex = fileName.lastIndexOf(".") + 1;
                var fileExtension = fileName.substr(dotIndex, fileName.length).toLowerCase();
                var isFileExtensionValid = _this.checkFileExtension(fileExtension);
                if (!isFileExtensionValid) {
                    _this.DOM.errorWrapper.removeClass("field-validation-valid");
                    _this.DOM.errorWrapper.addClass("field-validation-error a-message -error");
                    if (_this.DOM.input.data("format-error-msg")) {
                        _this.DOM.errorWrapper.html(_this.DOM.input.data("format-error-msg"));
                    }
                    else {
                        var formatsErrorMessage = "Veuillez sélectionner un fichier au format";
                        _this.formats.forEach(function (format, index) {
                            if (index !== _this.formats.length - 1) {
                                formatsErrorMessage += " " + format.toUpperCase() + ",";
                            }
                            else {
                                formatsErrorMessage += " ou " + format.toUpperCase() + ".";
                            }
                        });
                        _this.DOM.errorWrapper.html(formatsErrorMessage);
                    }
                }
                else {
                    if (fileSize < _this.options.minKo) {
                        _this.DOM.errorWrapper.removeClass("field-validation-valid");
                        _this.DOM.errorWrapper.addClass("field-validation-error a-message -error");
                        if (_this.DOM.input.data("small-file-error-msg")) {
                            _this.DOM.errorWrapper.html(_this.DOM.input.data("small-file-error-msg"));
                        }
                        else {
                            _this.DOM.errorWrapper.html("Votre fichier est trop petit. Essayez avec un autre fichier.");
                        }
                    }
                    else if (fileSize > _this.options.maxKo) {
                        _this.DOM.errorWrapper.removeClass("field-validation-valid");
                        _this.DOM.errorWrapper.addClass("field-validation-error a-message -error");
                        if (_this.DOM.input.data("big-file-error-msg")) {
                            _this.DOM.errorWrapper.html(_this.DOM.input.data("big-file-error-msg"));
                        }
                        else {
                            _this.DOM.errorWrapper.html("Votre fichier est trop lourd. Essayez avec un autre fichier.");
                        }
                    }
                    else {
                        _this.DOM.errorWrapper.removeClass("field-validation-error a-message -error");
                        _this.DOM.errorWrapper.addClass("field-validation-valid");
                        _this.DOM.errorWrapper.html("");
                        _this.readURL(input, fileName, fileExtension);
                    }
                }
            }
        });
    };
    return FileInput;
}());
;
