; (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.lazyloadvideos = {
        name: 'lazyloadvideos',
        version: '1.0.0',
        settings: {
            callback: function () { }
        },
        windowSize: {
            width: $(window).width(),
            height: $(window).height()
        },

        init: function (scope, method, options) {
            // initialisation � conserver
            Foundation.inherit(this, 'throttle random_str');
            var self = this;

            this.settings = this.settings || $.extend({}, this.defaults, (options || method));

            this.bindings(method, options);

        },

        events: function () {
            // initialisation pratique � conserver
            var self = this;
            var S = this.S;
            $(window)
                .on('resize.fndtn.lazyloadvideos', self.throttle(function () {
                    this.limits = {
                        //top: $("#cartSummary").offset().top - parseInt($("#cartSummary").css("top"), 10),
                        bottom: $("#main-footer").offset().top
                    }
                    this.windowSize = {
                        width: $(window).width(),
                        height: $(window).height()
                    };
                }, 100));
            Foundation.libs.lazyloadvideos.initGlobal();
            this.initBindings();
        },

        initBindings: function () { },

        initGlobal: function () {
            let videos = [].slice.call(
                document.querySelectorAll("video.js-lazyload-video")
            );

            if ("IntersectionObserver" in window) {
                self.lazyVideoObserver = new IntersectionObserver(function (
                    entries,
                    observer
                ) {
                    entries.forEach(function (video) {
                        if (video.isIntersecting) {
                            if (window.matchMedia("(max-width: 600px)").matches && video.target.getAttribute('data-mobile-poster') !== null) {
                                video.target.poster = video.target.getAttribute('data-mobile-poster')
                            } else {
                                video.target.poster = video.target.getAttribute('data-poster')
                            }
                            for (var source in video.target.children) {
                                var videoSource = video.target.children[source];
                                if (
                                    typeof videoSource.tagName === "string" &&
                                    videoSource.tagName === "SOURCE"
                                ) {
                                    if (window.matchMedia("(max-width: 600px)").matches && videoSource.getAttribute('data-mobile-src') !== null) {
                                        videoSource.src = videoSource.getAttribute('data-mobile-src');
                                    } else {
                                        videoSource.src = videoSource.dataset.src;
                                    }
                                }
                            }

                            video.target.load();
                            video.target.classList.remove("js-lazyload-video");
                            self.lazyVideoObserver.unobserve(video.target);
                        }
                    });
                });

                videos.forEach(function (lazyVideo) {
                    self.lazyVideoObserver.observe(lazyVideo);
                });
            }
        },

        reset: function () {
            if ("IntersectionObserver" in window) {
                self.lazyVideoObserver.disconnect();

                Foundation.libs.lazyloadvideos.initGlobal();
            } 
        }
    }
}(jQuery, this, this.document));