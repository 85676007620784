; (function ($, window, document, undefined) {
    'use strict';

    Foundation.libs.contentoverlay = {
        name: 'contentoverlay',

        version: '1.0.0',

        settings: {
            id: 'content-overlay',
            overlay: $('#content-overlay'),
            callback: function () { }
        },

        timer: 0,

        is_active: function (obj) {
            return (obj.length !== 0 && obj.css("display") !== "none");
        },

        init: function (scope, method, options) {
            Foundation.inherit(this,'');
            var self = this;

            // Création de l'élément HTML de l'overlay
            if (self.S("#" + this.settings.id).length === 0)
                self.S("#main-content", this.scope).append("<span id='" + this.settings.id + "'></span>");

            this.bindings(method, options);
        },

        show: function () {
                this.S("#" + this.settings.id).show();
        },
        hide: function () {
            //if (this.is_active(this.S("#" + this.settings.id)) === true) {
                this.S("#" + this.settings.id).hide();
            //}
        },

        events: function () {
            var self = this,
                S = this.S;

        },

        reflow: function () { }
    };
}(jQuery, this, this.document));


