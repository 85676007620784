var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var App = /** @class */ (function () {
    function App() {
        this.isInit = false;
        this.isDesktop = false;
        this.isTouch = false;
        this.$checker = document.querySelector('.hide-for-large-up');
        this.onResize();
        this.onResize = this.onResize.bind(this);
        this.detectTouchDevice();
        window.addEventListener('resize', this.debounce(this.onResize));
    }
    App.prototype.detectTouchDevice = function () {
        this.isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    };
    App.prototype.debounce = function (func) {
        var timer;
        return function (event) {
            if (timer)
                clearTimeout(timer);
            timer = setTimeout(func, 100, event);
        };
    };
    App.prototype.onResize = function () {
        if (this.$checker) {
            // Check si le device a changé depuis le dernier resize
            var oldValue = this.isDesktop;
            var display = getComputedStyle(this.$checker)['display'];
            if (display === "block") {
                this.isDesktop = false;
            }
            else {
                this.isDesktop = true;
            }
            if (this.isInit && oldValue !== this.isDesktop) {
                // Si oui alors on lance toutes les functions enregistrées dans this.functionsOnResize
                for (var i = 0; i < this.functionsOnResize.length; i++) {
                    this.functionsOnResize[i]();
                }
            }
        }
        this.isInit = true;
    };
    return App;
}());
var Navigation = /** @class */ (function (_super) {
    __extends(Navigation, _super);
    function Navigation(selector, options) {
        var _this = _super.call(this) || this;
        _this.DOM = {
            $navigation: null,
            $levels: null,
            $burger: null,
            $overlays: null
        };
        _this.options = {
            burgerSelector: ".menu",
            itemSelector: ".level",
            overlaySelector: '.nav-overlay'
        };
        _this.timerHoverLevel1 = null;
        _this.isOpen = false;
        _this.historic = [];
        var $item = document.querySelector(selector);
        // Stock tous les élements DOM dont la nav a besoin pour fonctionner si le selector est bon
        if ($item) {
            _this.selector = selector;
            _this.DOM.$navigation = $item;
            _this.options = __assign(__assign({}, _this.options), options);
            _this.DOM.$levels = _this.DOM.$navigation.querySelectorAll(_this.options.itemSelector + ':not([data-level="3"]).-sub');
            _this.DOM.$burger = document.querySelector(_this.options.burgerSelector);
            _this.DOM.$overlays = document.querySelectorAll(_this.options.overlaySelector);
        
            // bind this dans le scope de toutes les fonctions suivantes
            _this.onClickLevel = _this.onClickLevel.bind(_this);
            _this.onTouchLevel = _this.onTouchLevel.bind(_this);
            _this.onMouseenterLevel = _this.onMouseenterLevel.bind(_this);
            _this.onMouseleaveLevel = _this.onMouseleaveLevel.bind(_this);
            _this.onClickBurger = _this.onClickBurger.bind(_this);
            _this.onClickOverlay = _this.onClickOverlay.bind(_this);
            _this.resetNav = _this.resetNav.bind(_this);
            _this.initEvents = _this.initEvents.bind(_this);
            _this.removeEvents = _this.removeEvents.bind(_this);
            // Enregistre les fonctions à lancer au moment au resize
            // Tableau de fonctions avec : supprime les events actifs et les relance
            _this.functionsOnResize = [_this.resetNav, _this.removeEvents, _this.initEvents];
            //Init les évents au premier lancement
            _this.initEvents();
        }
        return _this;
    }
    Navigation.prototype.removeEvents = function () {
        // Remove tous les events enregistrés en fonction du device 
        if (this.isDesktop) {
            this.DOM.$burger.removeEventListener('click', this.onClickBurger);
        }
        for (var i = 0; i < this.DOM.$levels.length; i++) {
            var $level = this.DOM.$levels[i];
            var level = parseInt($level.dataset.level);
            var $link = $level.querySelector(this.options.itemSelector + '__link');
            if (!this.isDesktop) {
                if (level === 1) {
                    $link.removeEventListener('touchstart', this.onTouchLevel);
                    $level.removeEventListener('mouseenter', this.onMouseenterLevel);
                    $level.removeEventListener('mouseleave', this.onMouseleaveLevel);
                }
            }
            else {
                if ($link) {
                    $link.removeEventListener('click', this.onClickLevel);
                }
            }
            for (var i_1 = 0; i_1 < this.DOM.$overlays.length; i_1++) {
                this.DOM.$overlays[i_1].removeEventListener('click', this.onClickOverlay);
            }
        }
    };
    Navigation.prototype.initEvents = function () {
        // Enregistre tous les events en fonction du device 
        if (!this.isDesktop) {
            if (this.DOM.$burger) {
                this.DOM.$burger.addEventListener('click', this.onClickBurger);
            }
        }
        if (this.DOM.$levels) {
            for (var i = 0; i < this.DOM.$levels.length; i++) {
                var $level = this.DOM.$levels[i];
                var level = parseInt($level.dataset.level);
                var $link = $level.querySelector(this.options.itemSelector + '__link');
               
                if (this.isDesktop) {
                    if (level === 1) {
                        $link.addEventListener('touchstart', this.onTouchLevel);
                        $level.addEventListener('mouseenter', this.onMouseenterLevel);
                        $level.addEventListener('mouseleave', this.onMouseleaveLevel);
                    }
                }
                else {
                    if ($link && level > 0) {
                        $link.addEventListener('click', this.onClickLevel);
                    }
                }
            }
        }
        if (this.DOM.$overlays) {
            for (var i = 0; i < this.DOM.$overlays.length; i++) {
                this.DOM.$overlays[i].addEventListener('click', this.onClickOverlay);
            }
        }
    };
    Navigation.prototype.toggleOverlays = function (action) {
        for (var i = 0; i < this.DOM.$overlays.length; i++) {
            if (action === 'close') {
                this.DOM.$overlays[i].classList.remove('-active');
            }
            else if (action === 'open') {
                this.DOM.$overlays[i].classList.add('-active');
            }
        }
    };
    Navigation.prototype.lazyLoadingLevel = function ($level) {
        var $images = $level.querySelectorAll('.js-navLazyLoad');
        for (var i = 0; i < $images.length; ++i) {
            var $image = $images[i];
            LoadImage($image, '.js-navLazyLoad');
        }
    };
    Navigation.prototype.onMouseleaveLevel = function (e) {
        var $level = e.target;
        $level.classList.remove("-active");
        this.toggleOverlays('close');
        clearTimeout(this.timerHoverLevel1);
    };
    Navigation.prototype.onTouchLevel = function (e) {
        e.preventDefault();
        var $target = e.target;
        var $level = $target.parentElement;
        if (this.historic.length > 0) {
            var $historicLevel = this.historic[this.historic.length - 1];
            $historicLevel.classList.remove('-active');
            this.toggleOverlays('close');
            this.historic.pop();
            if ($historicLevel === $level) {
                return;
            }
        }
        $level.classList.add('-active');
        this.toggleOverlays('open');
        this.lazyLoadingLevel($level);
        this.historic.push($level);
    };
    Navigation.prototype.onMouseenterLevel = function (e) {
        var _this = this;
        var $level = e.target;
        this.timerHoverLevel1 = setTimeout(function () {
            if (_this.timerHoverLevel1) {
                $level.classList.add('-active');
                _this.toggleOverlays('open');
                _this.lazyLoadingLevel($level);
            }
        }, 100);
    };
    Navigation.prototype.onClickLevel = function (e) {
        e.preventDefault();
        var $target = e.target;
        var $level = $target.parentElement;
        var level = parseInt($level.dataset.level);
        if (level === 1) {
            $level.classList.add("-active");
            this.DOM.$burger.classList.add('back');
            this.historic.push($level);
            this.lazyLoadingLevel($level);
        }
        else {
            $level.classList.toggle("-active");
        }
    };
    Navigation.prototype.onClickOverlay = function (e) {
        this.DOM.$burger.classList.remove('open', 'back');
        this.DOM.$navigation.classList.remove('-active');
        this.toggleOverlays('close');
        if (this.historic.length !== 0) {
            this.historic[this.historic.length - 1].classList.remove('-active');
            this.historic.pop();
        }
        this.isOpen = false;
    };
    Navigation.prototype.resetNav = function () {
        this.closeNav();
        if (this.historic.length > 0) {
            this.moveBackNav();
        }
    };
    Navigation.prototype.openNav = function () {
        document.documentElement.classList.add("disable-scroll");
        this.toggleOverlays('open');
        this.DOM.$burger.classList.add('open');
        this.DOM.$navigation.classList.add('-active');
        this.isOpen = true;
    };
    Navigation.prototype.closeNav = function () {
        document.documentElement.classList.remove("disable-scroll");
        this.DOM.$burger.classList.remove('open');
        this.DOM.$navigation.classList.remove('-active');
        this.toggleOverlays('close');
        this.isOpen = false;
    };
    Navigation.prototype.moveBackNav = function () {
        this.historic[this.historic.length - 1].classList.remove('-active');
        this.DOM.$burger.classList.remove('back');
        this.historic.pop();
    };
    Navigation.prototype.onClickBurger = function (e) {
        if (!this.isOpen) {
            this.openNav();
        }
        else {
            if (this.historic.length === 0) {
                this.closeNav();
            }
            else {
                this.moveBackNav();
            }
        }
    };
    return Navigation;
}(App));
var navigation = new Navigation(".navigation", {
    burgerSelector: ".nav-burger",
    itemSelector: ".level"
});
